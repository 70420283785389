import { useQuery } from "@apollo/client";
import { navigate } from "gatsby";
import React, { useEffect, useState } from "react";

import { ParkingArea } from "../../Interfaces/ParkingArea";
import { Media } from "../../Interfaces/Media";

import { GetParkingAreaQuery } from "../../Query/ParkingArea";

import AreaOverSightCard from "./OverSightCard";

import NoImg from "../../images/no_image_icon@2x.png";
import EditIcon from "../../images/edit_icon3@2x.png";
import BikeIcon from "../../images/twowheeler_48@2x.png";
import CarIcon from "../../images/car_48@2x.png";
import BusIcon from "../../images/bus_48@2x.png";

import * as AreaOverSightStyle from "./AreaOverSight.module.scss";
import { getImageUrl } from "../../utils/GetPhotoUrl";
import { AxiosError } from "axios";
import { popoverAlert } from "../../utils/Alert";
import moment from "moment";

interface Props {
  areaId: string;
}

const Index = ({ areaId }: Props) => {
  const [areaImages, setAreaImages] = useState<Media[]>();
  const { data } = useQuery<{ parkingArea: ParkingArea }>(GetParkingAreaQuery, {
    variables: {
      id: `parking_areas/${areaId}`,
    },
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    const media = data?.parkingArea.parkingMedia?.edges;
    if (media?.length) {
      getImageUrl(media[0]?.node.id, "parking")
        .then((response) => {
          setAreaImages([response]);
        })
        .catch((error: AxiosError) => {
          popoverAlert({ msg: error.response?.data.detail, icon: "error" });
        });
    }
  }, [data?.parkingArea]);

  const getPriceByVehicle = (
    vehicleType: "2W" | "4W" | "OTHER",
    priceType: "PER_DAY" | "PER_HOUR"
  ) => {
    return 2;
  };

  return (
    <div className={AreaOverSightStyle.container}>
      <div className={AreaOverSightStyle.header}>
        <h5 className="boldText color-primary textMd">
          Oversignt of entered information
        </h5>
        <button
          onClick={() => {
            if (data?.parkingArea.category?.code === "CHARGING") {
              navigate(`/edit/charging/${areaId}`);
            }
            if (data?.parkingArea.category?.code === "PARKING") {
              navigate(`/edit/parking/${areaId}`);
            }
          }}
        >
          <span>Edit</span>
          <img src={EditIcon} alt="edit" width={12} height={12} />
        </button>
      </div>
      <div className={AreaOverSightStyle.card}>
        <div className={`${AreaOverSightStyle.card__header} grediant1`}>
          <h5>Summary of entered details</h5>
        </div>
        <div className={AreaOverSightStyle.card__content}>
          <AreaOverSightCard title={"Address"}>
            <div className={AreaOverSightStyle.card__address}>
              <div>
                <span>
                  {
                    data?.parkingArea.parkingAreaAddresses.collection[0]
                      ?.address
                  }
                  ,
                  {
                    data?.parkingArea.parkingAreaAddresses.collection[0]?.city
                      .name
                  }
                  ,
                  {
                    data?.parkingArea.parkingAreaAddresses.collection[0]?.state
                      .name
                  }
                </span>
              </div>
              <div>
                <span>
                  {data?.parkingArea.category?.code === "CHARGING"
                    ? "No .of charging stations:"
                    : "No. of parking bays:"}
                </span>
                &nbsp;
                <span>{data?.parkingArea.totalSpot}</span>
              </div>
            </div>
          </AreaOverSightCard>

          <AreaOverSightCard title="Price">
            <div className={AreaOverSightStyle.card__prices}>
              <div className={AreaOverSightStyle.card__prices__item}>
                <img width={48} height={48} src={BikeIcon} alt="twowheeler" />
                <span>
                  &#8377; &nbsp; {getPriceByVehicle("2W", "PER_HOUR")}/Hr
                </span>
                <span>
                  &#8377; &nbsp; {getPriceByVehicle("2W", "PER_DAY")}/Day
                </span>
              </div>
              <div className={AreaOverSightStyle.card__prices__item}>
                <img width={48} height={48} src={CarIcon} alt="twowheeler" />
                <span>
                  &#8377; &nbsp; {getPriceByVehicle("4W", "PER_HOUR")}/Hr
                </span>
                <span>
                  &#8377; &nbsp; {getPriceByVehicle("4W", "PER_DAY")}/Day
                </span>
              </div>
              <div className={AreaOverSightStyle.card__prices__item}>
                <img width={48} height={48} src={BusIcon} alt="twowheeler" />
                <span>
                  &#8377; &nbsp; {getPriceByVehicle("OTHER", "PER_HOUR")}/Hr
                </span>
                <span>
                  &#8377; &nbsp; {getPriceByVehicle("OTHER", "PER_DAY")}/Day
                </span>
              </div>
            </div>
          </AreaOverSightCard>
          <AreaOverSightCard title="Pictures">
            <div className={AreaOverSightStyle.card__images}>
              <img
                src={areaImages?.length ? areaImages[0].url.url : NoImg}
                alt=""
                width={100}
                height={100}
              />
              <button>Click here to see all pictures</button>
            </div>
          </AreaOverSightCard>
          <AreaOverSightCard
            title={
              data?.parkingArea.category?.code === "CHARGING"
                ? "Charging Station is available"
                : "Parking area is available"
            }
          >
            <div className={AreaOverSightStyle.card__available}></div>
          </AreaOverSightCard>
          <AreaOverSightCard
            title={
              data?.parkingArea.category?.code === "CHARGING"
                ? "Charging Station is not available"
                : "Parking area is not available"
            }
          >
            <div className={AreaOverSightStyle.card__notavailable}>
              {data?.parkingArea.parkingAvailabilities.collection
                .filter(({ status }) => status.code === "ACTIVE")
                .map(({ id, fromDate, toDate }) => (
                  <div key={id}>
                    <span>{moment(fromDate).format("DD/MM/YYYY hh:mm")}</span>
                    <span>To</span>
                    <span>{moment(toDate).format("DD/MM/YYYY hh:mm")}</span>
                  </div>
                ))}
            </div>
          </AreaOverSightCard>
        </div>
        <div className={AreaOverSightStyle.submit}>
          <button onClick={() => navigate("/")}>Submit</button>
        </div>
      </div>
    </div>
  );
};

export default Index;
