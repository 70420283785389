import React, { ReactElement } from "react";

import * as OverSightCardStyle from "./OverSightCard.module.scss";

interface Props {
  title: string;
  children: ReactElement | ReactElement[];
}

const Index = ({ title, children }: Props) => {
  return (
    <div className={OverSightCardStyle.card}>
      <div className={OverSightCardStyle.card__header}>
        <span>{title}:</span>
      </div>
      <div className={OverSightCardStyle.card__content}>{children}</div>
    </div>
  );
};

export default Index;
