import React from "react";

import Layout from "../../Components/Layout";
import AreaOverSight from "../../Components/AreaOverSight";

const OverSightPage = ({ id }) => {
  return (
    <Layout isPrivate showHeader>
      <AreaOverSight areaId={id} />
    </Layout>
  );
};

export default OverSightPage;
