// extracted by mini-css-extract-plugin
export var container = "AreaOverSight-module--container--r-96A";
export var header = "AreaOverSight-module--header--nbN6z";
export var card = "AreaOverSight-module--card--aLuU1";
export var card__header = "AreaOverSight-module--card__header--2WsjI";
export var card__content = "AreaOverSight-module--card__content--gQsJg";
export var card__address = "AreaOverSight-module--card__address--MQHCn";
export var card__images = "AreaOverSight-module--card__images--e4wtY";
export var card__prices = "AreaOverSight-module--card__prices--EHuxq";
export var card__prices__item = "AreaOverSight-module--card__prices__item--yeupQ";
export var card__available = "AreaOverSight-module--card__available--vbFu6";
export var card__notavailable = "AreaOverSight-module--card__notavailable--JTO30";
export var submit = "AreaOverSight-module--submit--wXSUb";