import { Media } from "../Interfaces/Media";
import Server from "./Server";

export const getImageUrl = (
  mediaId: string,
  type: "user" | "parking"
): Promise<Media> => {
  return new Promise((resolve, reject) => {
    const shortMediaId = mediaId.split("/")[2];
    Server.get(`/${type}/media/${shortMediaId}/path`)
      .then((res: any) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
